import { Box, Container, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useSiteContext } from '../SiteContext';
import { useUser } from './Account/useUser';
import { CurrentSurveyForSite, useGetSite } from './CurrentSurveyForSite';

export const SiteAndSurveys = () => {
    const { spacing } = useTheme();
    const { user } = useUser();
    const { siteId } = useSiteContext();
    const site = useGetSite(siteId);
    return (
        <Container component='section' maxWidth='xl'>
            <Box color='text.secondary' m={spacing(3, 0)}>
                <Typography variant='h3' style={{ margin: spacing(1, 0) }}>
                    Hi, {user?.firstName ?? ''}
                </Typography>
                {!site.data && (
                    <>
                        <Typography
                            style={{ margin: spacing(0.5, 0) }}
                            variant='h5'
                        >
                            Please login using the provided QR Code or Short Url
                        </Typography>
                    </>
                )}
                {site.data && (
                    <>
                        <Typography
                            style={{ margin: spacing(0.5, 0) }}
                            variant='h4'
                        >
                            Welcome to {site.data.sites_by_pk.name}
                        </Typography>
                        <Typography style={{ margin: spacing(0.5, 0) }}>
                            {site.data.sites_by_pk.address}
                        </Typography>
                        <CurrentSurveyForSite />
                    </>
                )}
            </Box>
        </Container>
    );
};
