import { Router, useLocation } from '@reach/router';
import React from 'react';
import { Home } from '../components/Account/Home';
import { Profile } from '../components/Account/Profile';
import { getIdToken, getProfile, isAuthenticated, login } from '../utils/auth';
import { SiteContextProvider } from '../SiteContext';
import { PageRedirect } from '../components/LoginRedirect';
import { CompletedSurveys } from '../components/Account/CompletedSurveys';

const Account = () => {
    const { search } = useLocation();

    if (!isAuthenticated()) {
        login(search);
        return <PageRedirect message='Redirecting to Login' />;
    }
    const idToken = getIdToken();
    return (
        <Router>
            <Home path='/account' token={idToken} />
            <Profile path='/account/profile' />
            <CompletedSurveys path='/account/completed-surveys' />
        </Router>
    );
};

export default Account;
