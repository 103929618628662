import React from 'react';
import { useTheme, Typography, Button } from '@material-ui/core';
import { Link } from 'gatsby';

export const UpdatedDetails = ({ clearUpdate, dataToRefetch, details }) => {
    const { spacing } = useTheme();
    return (
        <>
            <Typography>
                Thank you, for updating your {details} details
            </Typography>
            <Button
                variant='contained'
                color='primary'
                style={{ margin: `${spacing(3, 0, 2)}` }}
                component={Link}
                to={window.location.pathname}
                onClick={async () => {
                    dataToRefetch().then((res) => {
                        clearUpdate();
                    });
                }}
            >
                Continue
            </Button>
        </>
    );
};
