import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Layout from '../Layout';
import { UpdateUserDetailsForm } from '../UpdateUserDetailsForm';
import {
    Container,
    Typography,
    useTheme,
    Box,
    Divider,
} from '@material-ui/core';
import { UpdateUserMedicaDetailsForm } from '../UpdateMedicalDetailsForm';

export const Profile = (props: RouteComponentProps) => {
    const { spacing } = useTheme();
    return (
        <Layout heading='Profile' title='Profile'>
            <Container component='section' maxWidth='xl'>
                <Box color='text.secondary' m={spacing(2, 1)}>
                    <Typography variant='h5'>Personal Details</Typography>
                    <UpdateUserDetailsForm />
                    <Divider style={{ margin: spacing(3, 0) }} />
                    <Typography variant='h5'>Medical Details</Typography>
                    <UpdateUserMedicaDetailsForm />
                </Box>
            </Container>
        </Layout>
    );
};
