import { Backdrop, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Layout from './Layout';
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 2,
        color: '#fff',
    },
}));
export const PageRedirect = ({ message }) => {
    const classes = useStyles();
    return (
        <Layout title='' heading=''>
            <Backdrop open className={classes.backdrop}>
                <CircularProgress color='inherit' />
                <Typography>{message}</Typography>
            </Backdrop>
        </Layout>
    );
};
