import { gql, useMutation, useQuery } from '@apollo/client';
import { getIdToken } from '../../utils/auth';
import { useUser } from '../Account/useUser';

const UPDATE_MEDICAL_INFO = gql`
    mutation UpdateMedical($medicalInfo: MedicalInfoInputType) {
        updateMedical(medicalInfo: $medicalInfo) {
            updated
        }
    }
`;

const GET_MEDICAL_DETAILS_FOR_USER = gql`
    query GetMedicalDetailsForUser($userId: String!) {
        medical_by_pk(userId: $userId) {
            expiry
            hasCompleted
            dateModified
        }
    }
`;

export const useGetMedicalDetailsForUser = () => {
    const { user, loading: userLoading, error: userError } = useUser();
    const token = getIdToken();
    const getMedical = useQuery(GET_MEDICAL_DETAILS_FOR_USER, {
        variables: { userId: user?.id },
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    return getMedical;
};
export const useUpsertMedicalDetailsForUser = () => {
    const token = getIdToken();
    const upsertMedical = useMutation(UPDATE_MEDICAL_INFO, {
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    return upsertMedical;
};
