import { useQuery, gql } from '@apollo/client';
import { getIdToken } from '../../utils/auth';
import { useState, useEffect } from 'react';

export interface User {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    id: string;
    address?: string;
    dateOfBirth?: Date;
    gender?: string;
    membership?: string;
    sfPersonId?: string;
    noMedicareCard: boolean;
}

const GET_USER_DETAILS = gql`
    query GetUserDetails {
        users {
            id
            sfPersonId
            firstName
            lastName
            email
            mobile
            address
            dateOfBirth
            gender
            membership
            noMedicareCard
        }
    }
`;

export const useUser = () => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const token = getIdToken();
    const userQuery = useQuery(GET_USER_DETAILS, {
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery?.data?.users?.[0]);
        }
    }, [userQuery]);

    return {
        user,
        loading: userQuery.loading,
        error: userQuery.error,
        query: userQuery,
    };
};
