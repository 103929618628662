import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
    useTheme,
    LinearProgress,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUser } from '../Account/useUser';
import { UpdatedDetails } from '../UpdatedDetails';
import {
    useGetMedicalDetailsForUser,
    useUpsertMedicalDetailsForUser,
} from './graphql';
import * as Sentry from '@sentry/browser';
type UpsertMedicalDetailsFormData = {
    medicareNumber: string;
    expiry: Date;
    ref: string;
};

function validateMedicare(medicare) {
    let isValid = false;
    if (medicare && medicare.length === 10) {
        const matches = medicare.match(/^(\d{8})(\d)/);

        if (!matches) {
            return { invalid: true };
        }

        const base = matches[1];
        const checkDigit = matches[2];
        const weights = [1, 3, 7, 9, 1, 3, 7, 9];

        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            sum += parseInt(base[i], 10) * weights[i];
        }

        isValid = sum % 10 === parseInt(checkDigit, 10);
    }

    return isValid;
}
yup.addMethod<any>(yup.string, 'validateMedicare', function () {
    return this.test({
        name: 'name',
        message: 'Medicare Number is not valid',
        test: (medicare = '') => {
            return validateMedicare(medicare);
        },
    });
});

const schema = yup.object().shape({
    medicareNumber: yup
        .string()
        .length(10, 'Medicare Number should be 10 characters')
        .matches(/^\d+$/g, 'Medicare Number should only be numbers')
        .validateMedicare()
        .required('Medicare Number is Required'),
    expiry: yup
        .date()
        .min(new Date(), 'Expiry cannot be in the past')
        .required('Expiry is required'),
    ref: yup
        .string()
        .length(
            1,
            'Reference should be one number, and is listed next to your name'
        )
        .matches(/[0-9]/g, 'Reference should be a number')
        .required('Reference is required'),
});
export const UpdateUserMedicaDetailsForm = ({
    onComplete,
    onCancel,
}: {
    onComplete?: () => void;
    onCancel?: () => void;
}) => {
    const { user, loading: userLoading, error: userError } = useUser();
    const medicalData = useGetMedicalDetailsForUser();
    const { spacing } = useTheme();
    const [updatedData, setUpdatedData] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState<boolean>(
        false
    );

    const [
        upsertMedical,
        { data, loading, error },
    ] = useUpsertMedicalDetailsForUser();

    useEffect(() => {
        if (data) {
            setUpdatedData(true);
            if (onComplete) {
                onComplete();
            }
        }
    }, [data, onComplete]);

    const { register, handleSubmit, errors, control } = useForm<
        UpsertMedicalDetailsFormData
    >({ resolver: yupResolver(schema) });

    const onSubmit = handleSubmit(({ expiry: exp, ...formValues }, e) => {
        const expiry = new DateFnsUtils().format(exp, 'yyyy-MM-dd');
        upsertMedical({
            variables: {
                medicalInfo: {
                    ...formValues,
                    expiry,
                    id: user.id,
                },
            },
        })
            .then(() => {
                setFormSubmissionError(false);
            })
            .catch((error) => {
                Sentry.captureException(error);
                setFormSubmissionError(true);
            });
    });

    if (updatedData) {
        return (
            <UpdatedDetails
                details='medical'
                dataToRefetch={() => medicalData.refetch()}
                clearUpdate={() => setUpdatedData(false)}
            />
        );
    }

    if (!user || userLoading || medicalData.loading) {
        return <LinearProgress />;
    }

    if (userError || medicalData.error) {
        return (
            <Alert style={{ marginTop: spacing(1) }} severity='error'>
                <AlertTitle>Error</AlertTitle>
                There was an error loading your details, please see a member of
                the team.
            </Alert>
        );
    }

    return (
        <>
            <Typography>
                {medicalData?.data?.medical_by_pk?.hasCompleted === false ||
                medicalData?.data?.medical_by_pk?.dateModified
                    ? `You last updated your medicare details on ${medicalData?.data?.medical_by_pk?.dateModified}`
                    : `Please provide your medicare details`}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form onSubmit={onSubmit}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: spacing(2) }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                error={!!errors?.medicareNumber}
                                helperText={errors?.medicareNumber?.message}
                                inputRef={register}
                                required
                                id='medicareNumber'
                                name='medicareNumber'
                                label='Medicare Number'
                                placeholder='Medicare Number'
                                defaultValue={''}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                as={KeyboardDatePicker}
                                views={['month', 'year']}
                                error={!!errors?.expiry}
                                helperText={errors?.expiry?.message}
                                required
                                id='expiry'
                                name='expiry'
                                label='Expiry'
                                format='MM/yyyy'
                                initialFocusedDate={null}
                                defaultValue={null}
                                value={null}
                                disablePast
                                onChange={() => null}
                                fullWidth
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                inputVariant='outlined'
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                error={!!errors?.ref}
                                helperText={errors?.ref?.message}
                                inputRef={register}
                                required
                                id='ref'
                                name='ref'
                                label='Reference'
                                placeholder='Ref'
                                defaultValue={''}
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {formSubmissionError ? (
                        <Alert
                            style={{ marginTop: spacing(1) }}
                            severity='error'
                        >
                            <AlertTitle>Error</AlertTitle>
                            Sorry, there was a problem submitting your details.
                            <br />
                            Please try entering your details again, or see the
                            team for assistance.
                        </Alert>
                    ) : (
                        <></>
                    )}
                    <div style={{ position: 'relative' }}>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            style={{ margin: `${spacing(3, 0, 2)}` }}
                            disabled={loading}
                        >
                            Update
                        </Button>
                        {onCancel !== undefined && (
                            <Button
                                type='button'
                                onClick={() => onCancel()}
                                fullWidth
                                variant='contained'
                                color='secondary'
                                style={{ margin: `${spacing(3, 0, 2)}` }}
                                disabled={loading}
                            >
                                I do not have a medicare card
                            </Button>
                        )}
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: -12,
                                    marginLeft: -12,
                                }}
                                size={24}
                            />
                        )}
                    </div>
                </form>
            </MuiPickersUtilsProvider>
        </>
    );
};
