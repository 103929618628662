import {
    Backdrop,
    CircularProgress,
    Container,
    makeStyles,
    Typography,
    Box,
    useTheme,
} from '@material-ui/core';
import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import React from 'react';
import { logout, getProfile, getIdToken } from '../../utils/auth';
import Layout from '../Layout';
import { UpdateUserDetailsForm } from '../UpdateUserDetailsForm';
import { useUser } from './useUser';
import { SiteAndSurveys } from '../SiteAndSurveys';
import queryString from 'query-string';
import { useSiteContext } from '../../SiteContext';
import { useGetMedicalDetailsForUser } from '../UpdateMedicalDetailsForm/graphql';
import { UpdateUserMedicaDetailsForm } from '../UpdateMedicalDetailsForm';
import { useUpdateUserDetailsMutation } from '../UpdateUserDetailsForm/graphql';

interface HomeProps extends RouteComponentProps {
    token: string;
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const Home = ({ token }: HomeProps) => {
    const { spacing } = useTheme();
    const { search } = useLocation();
    const { siteId } = queryString.parse(search);
    const { setSiteId } = useSiteContext();
    const medicalData = useGetMedicalDetailsForUser();
    const [
        updateUser,
        { data, loading: updateLoading, error: updateError },
    ] = useUpdateUserDetailsMutation();
    const { query: userQuery, user, loading, error } = useUser();
    if (siteId) {
        setSiteId(siteId as string);
    }
    const classes = useStyles();
    // call logout function if no token provided when rendering
    if (!token) {
        logout();
        return <Redirect path='/' />;
    }

    const userNeedsUpdate = !user?.sfPersonId;
    const hasMedical = medicalData?.data?.medical_by_pk?.hasCompleted;
    const noMedicareCard = user?.noMedicareCard;

    return (
        <Layout heading='Home' title='Home'>
            {(loading || updateLoading) && (
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color='inherit' />
                </Backdrop>
            )}
            {user && (
                <Container component='section' maxWidth='xl'>
                    {!userNeedsUpdate && (hasMedical || noMedicareCard) ? (
                        <SiteAndSurveys />
                    ) : userNeedsUpdate ? (
                        <Box color='text.secondary' m={spacing(0.5, 0)}>
                            <Typography variant='h5'>
                                Please set up your account by completing the
                                following details.
                            </Typography>
                            <Typography variant='h5'>
                                Your details will be saved for future visits.
                            </Typography>

                            <UpdateUserDetailsForm />
                        </Box>
                    ) : (
                        !userNeedsUpdate &&
                        !hasMedical &&
                        !noMedicareCard && (
                            <Box color='text.secondary' m={spacing(0.5, 0)}>
                                <Typography variant='h5'>
                                    Please complete your medicare details
                                </Typography>

                                <UpdateUserMedicaDetailsForm
                                    onCancel={async () => {
                                        await updateUser({
                                            variables: {
                                                person: {
                                                    id: user?.id,
                                                    firstName: user?.firstName,
                                                    lastName: user?.lastName,
                                                    dateOfBirth:
                                                        user?.dateOfBirth,
                                                    mobile: user?.mobile,
                                                    address: user?.address,
                                                    membership:
                                                        user?.membership,
                                                    email: user?.email,
                                                    gender: user?.gender,
                                                    noMedicareCard: true,
                                                },
                                            },
                                        });
                                        await userQuery.refetch();
                                    }}
                                    onComplete={async () =>
                                        await medicalData.refetch()
                                    }
                                />
                            </Box>
                        )
                    )}
                </Container>
            )}
        </Layout>
    );
};
