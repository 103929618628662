import { gql, useMutation, useQuery } from '@apollo/client';
import { IQuestionAnswer, Survey } from '@fox/forms-renderer';
import { LinearProgress, Typography, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { useSiteContext } from '../SiteContext';
import { getIdToken } from '../utils/auth';
import { useUser } from './Account/useUser';
import { ThemeProvider } from 'styled-components';

const SURVEY_THEME = {
    mobileHeader: 'linear-gradient(90deg, #393f99, #265449)',
    colors: {
        pageBackground:
            'linear-gradient(180deg, rgba(65, 56, 182, 1), rgba(39, 87, 68, 1) 100%, rgba(39, 87, 68, 1))',
        white: '#ffffff',
        font: '#67676f',
        darkGrey: '#67676f',
        midGrey: '#ececec',
        paleGrey: '#f2f2f2',
        blockGrey: '#e9e9ea',
        darkerGrey: '#67676e',
        primary: '#255642',
        secondary: '#52649a',
        highlight: '#eb6321',
        charcoal: '#58595b',
        good: '#4caa65',
        bad: '#d62c17',
        neutral: '#3244b6',
        body: '#929497',
        sidebarBG: '#58595B',
        topbarBG: '#e9e9ea',
        border: 'rgba(88, 89, 91, 0.5)',
        tabActive: '#325543',
        tabBorder: '#B0B0B0',
        collapseBorder: '#B0B0B0',
    },
    regular: 300,
    medium: 400,
    bold: 500,
    buttonTransition: 'all ease-in-out 0.3s',
    sidebarWidth: 50,
    padding: 20,
};

const SurveyThemeProvider: React.FC<{}> = ({ children }) => {
    return <ThemeProvider theme={SURVEY_THEME}>{children}</ThemeProvider>;
};

const GET_SITE_BY_ID = gql`
    query GetSiteByID($id: uuid!) {
        sites_by_pk(id: $id) {
            name
            address
        }
    }
`;

const COMPLETE_SURVEY = gql`
    mutation SubmitSurveyResponse(
        $surveyResponse: SurveyResponseInputType!
        $siteId: String!
    ) {
        submitSurveyResponse(surveyResponse: $surveyResponse, siteId: $siteId) {
            formId
            createdFor {
                id
            }
        }
    }
`;

export const useGetSite = (siteId) => {
    const token = getIdToken();
    return useQuery(GET_SITE_BY_ID, {
        variables: { id: siteId },
        context: { headers: { Authorization: 'Bearer ' + token } },
        skip: !siteId,
    });
};

const useCompleteSurvey = () => {
    const token = getIdToken();
    const completeSurvey = useMutation(COMPLETE_SURVEY, {
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    return completeSurvey;
};

const GET_SURVEY_BY_SITE_ID_QUERY = gql`
    query GetSurveyBySiteId($siteId: String!) {
        getSurveyBySiteId(siteId: $siteId) {
            layout
        }
    }
`;

const useGetSurveyBySiteId = (siteId) => {
    const token = getIdToken();
    return useQuery(GET_SURVEY_BY_SITE_ID_QUERY, {
        context: { headers: { Authorization: 'Bearer ' + token } },
        variables: { siteId },
    });
};

export const CurrentSurveyForSite = () => {
    const { spacing } = useTheme();
    const { siteId } = useSiteContext();
    const [submitted, setSubmitted] = useState(false);
    const [completeSurvey] = useCompleteSurvey();
    const { user } = useUser();

    const {
        data: surveyData,
        loading: surveyLoading,
        error: surveyError,
    } = useGetSurveyBySiteId(siteId);

    const handleCompleteSurvey = async (
        submittedAnswers: IQuestionAnswer[]
    ) => {
        const answers = submittedAnswers.map((a) => {
            const { questionName, answer } = a;
            return {
                questionName,
                ...answer,
            };
        });
        return completeSurvey({
            variables: {
                surveyResponse: {
                    userId: user.id,
                    answers,
                },
                siteId,
            },
        });
    };

    if (surveyLoading) {
        return <LinearProgress />;
    }
    if (surveyError) {
        return (
            <Alert style={{ marginTop: spacing(1) }} severity='error'>
                <AlertTitle>Error</AlertTitle>
                There was an error loading the survey
                <br />
                Please try logging in again, or see the team for assistance.
            </Alert>
        );
    }

    if (submitted) {
        return (
            <>
                <Typography>
                    Thank you for submitting your survey response
                </Typography>
            </>
        );
    }

    return (
        <SurveyThemeProvider>
            {surveyData?.getSurveyBySiteId?.layout && (
                <Survey
                    formDef={surveyData?.getSurveyBySiteId}
                    metadata={{}}
                    onSubmit={handleCompleteSurvey}
                    onSubmitted={(res) => {
                        setSubmitted(true);
                    }}
                />
            )}
        </SurveyThemeProvider>
    );
};
