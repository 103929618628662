import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
    LinearProgress,
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import parse from 'autosuggest-highlight/parse';
import 'date-fns';
import throttle from 'lodash/throttle';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUser } from '../Account/useUser';
import { UpdatedDetails } from '../UpdatedDetails';
import { useErrorThrower, useUpdateUserDetailsMutation } from './graphql';

type UpdateUserDetailsFormData = {
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    dateOfBirth: Date;
    address: string;
    gender: string;
    membership: string;
};
const phoneRegExp = /^04[0-9]{8}/;

const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup
        .string()
        .email('Please make sure your email is valid')
        .required('Email is required'),
    mobile: yup
        .string()
        .required('mobile number is required')
        .matches(phoneRegExp, 'mobile number is not valid'),
    dateOfBirth: yup
        .date()
        .max(new Date(), 'Date of Birth must be in the past')
        .required('Date of Birth is required'),
    address: yup.string().required('Address is required'),
    gender: yup.string().required('Gender is required'),
    membership: yup.string().required('Membership is required'),
});

interface PlaceType {
    description: string;
    structured_formatting: {
        main_text: string;
        secondary_text?: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            }
        ];
    };
}
const autocompleteService = { current: null };
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export const UpdateUserDetailsForm = () => {
    const classes = useStyles();
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const [addressValue, setAddressValue] = React.useState<PlaceType | null>(
        null
    );
    const {
        user,
        loading: userLoading,
        error: userError,
        query: userQuery,
    } = useUser();
    const { spacing } = useTheme();
    const [updatedData, setUpdatedData] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState<boolean>(
        false
    );
    const [
        updateUser,
        { data, loading, error },
    ] = useUpdateUserDetailsMutation();

    useEffect(() => {
        if (data) {
            setUpdatedData(true);
        }
    }, [data]);

    const fetchAddress = React.useMemo(
        () =>
            throttle(
                (
                    request: {
                        input: string;
                        componentRestrictions: { country: 'au' };
                    },
                    callback: (results?: PlaceType[]) => void
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback
                    );
                },
                500
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(addressValue ? [addressValue] : []);
            return undefined;
        }
        fetchAddress(
            { input: inputValue, componentRestrictions: { country: 'au' } },
            (results?: PlaceType[]) => {
                if (active) {
                    let newOptions = [] as PlaceType[];

                    if (addressValue) {
                        newOptions = [addressValue];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            }
        );

        return () => {
            active = false;
        };
    }, [addressValue, inputValue, fetchAddress]);
    const { register, handleSubmit, errors, control, setValue } = useForm<
        UpdateUserDetailsFormData
    >({ resolver: yupResolver(schema) });

    useEffect(() => {
        if (user?.address) {
            setInputValue(user.address);
            setValue('address', user.address);
        }
    }, [setValue, user]);

    const onSubmit = handleSubmit(({ dateOfBirth: dob, ...formValues }, e) => {
        const dateOfBirth = new DateFnsUtils().format(dob, 'yyyy-MM-dd');
        updateUser({
            variables: {
                person: {
                    ...formValues,
                    dateOfBirth,
                    id: user.id,
                    noMedicareCard: user?.noMedicareCard,
                },
            },
        })
            .then(() => {
                setFormSubmissionError(false);
                userQuery.refetch();
            })
            .catch((error) => {
                Sentry.captureException(error);
                setFormSubmissionError(true);
            });
    });

    if (updatedData) {
        return (
            <UpdatedDetails
                details='personal'
                dataToRefetch={() => userQuery.refetch()}
                clearUpdate={() => setUpdatedData(false)}
            />
        );
    }

    if (!user || userLoading) {
        return <LinearProgress />;
    }

    if (userError) {
        return (
            <Alert style={{ marginTop: spacing(1) }} severity='error'>
                <AlertTitle>Error</AlertTitle>
                There was an error loading your details, please try again,
                re-login, or see a team member.
            </Alert>
        );
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form onSubmit={onSubmit}>
                <Grid container spacing={2} style={{ marginTop: spacing(2) }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={!!errors?.firstName}
                            helperText={errors?.firstName?.message}
                            inputRef={register}
                            required
                            id='firstName'
                            name='firstName'
                            label='First Name'
                            placeholder='First Name'
                            defaultValue={user.firstName}
                            variant='outlined'
                            autoFocus
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={!!errors?.lastName}
                            helperText={errors?.lastName?.message}
                            inputRef={register}
                            required
                            id='lastName'
                            name='lastName'
                            label='Last Name'
                            placeholder='Last Name'
                            defaultValue={user.lastName}
                            variant='outlined'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                            inputRef={register}
                            required
                            id='email'
                            name='email'
                            label='Email Address'
                            placeholder='Email Address'
                            defaultValue={user.email}
                            variant='outlined'
                            autoComplete='email'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={!!errors?.mobile}
                            helperText={errors?.mobile?.message}
                            inputRef={register}
                            required
                            id='mobile'
                            name='mobile'
                            label='Mobile Number'
                            placeholder='Mobile Number'
                            variant='outlined'
                            defaultValue={user.mobile}
                            fullWidth
                            autoComplete='mobile'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name='dateOfBirth'
                            as={KeyboardDatePicker}
                            error={!!errors?.dateOfBirth}
                            helperText={errors?.dateOfBirth?.message}
                            id='dateOfBirth'
                            required
                            label='Date of Birth'
                            format='dd/MM/yyyy'
                            initialFocusedDate={null}
                            defaultValue={user.dateOfBirth}
                            value={user.dateOfBirth}
                            disableFuture
                            onChange={() => null}
                            fullWidth
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant='outlined'
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            defaultValue={addressValue}
                            inputValue={inputValue}
                            fullWidth
                            getOptionLabel={(option) =>
                                typeof option === 'string'
                                    ? option
                                    : option.description
                            }
                            filterOptions={(x) => x}
                            options={options}
                            autoComplete
                            autoSelect
                            filterSelectedOptions
                            value={addressValue}
                            onChange={(
                                event: any,
                                newValue: PlaceType | null
                            ) => {
                                setInputValue(newValue?.description);
                                setOptions(
                                    newValue ? [newValue, ...options] : options
                                );
                                setAddressValue(newValue);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id='address'
                                    name='address'
                                    inputRef={register}
                                    error={!!errors?.address}
                                    helperText={errors?.address?.message}
                                    label='Enter your address'
                                    variant='outlined'
                                    fullWidth
                                    required
                                />
                            )}
                            renderOption={(option) => {
                                const matches =
                                    option.structured_formatting
                                        .main_text_matched_substrings;
                                const parts = parse(
                                    option.structured_formatting.main_text,
                                    matches.map((match: any) => [
                                        match.offset,
                                        match.offset + match.length,
                                    ])
                                );

                                return (
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <LocationOnIcon
                                                className={classes.icon}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight
                                                            ? 700
                                                            : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                            <Typography
                                                variant='body2'
                                                color='textSecondary'
                                            >
                                                {
                                                    option.structured_formatting
                                                        .secondary_text
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant='outlined'
                            error={!!errors?.gender}
                            required
                        >
                            <InputLabel id='gender-label'>Gender</InputLabel>
                            <Controller
                                name='gender'
                                as={Select}
                                fullWidth
                                labelId='gender-label'
                                id='gender'
                                defaultValue={user.gender ?? ''}
                                value={user.gender ?? ''}
                                label='Gender'
                                control={control}
                            >
                                <MenuItem value='' disabled>
                                    Please choose:
                                </MenuItem>
                                <MenuItem value={'M'}>Male</MenuItem>
                                <MenuItem value={'F'}>Female</MenuItem>
                                <MenuItem value={'O'}>Other</MenuItem>
                            </Controller>
                            <FormHelperText>
                                {errors?.membership?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            variant='outlined'
                            error={!!errors?.membership}
                            required
                        >
                            <InputLabel id='membership-label'>
                                Membership
                            </InputLabel>
                            <Controller
                                name='membership'
                                as={Select}
                                fullWidth
                                labelId='membership-label'
                                id='membership'
                                defaultValue={user.membership ?? ''}
                                value={user.membership ?? ''}
                                label='Membership'
                                control={control}
                            >
                                <MenuItem value='' disabled>
                                    Please choose:
                                </MenuItem>
                                <MenuItem value={'Incolink'}>Incolink</MenuItem>
                                <MenuItem value={'ETU'}>ETU</MenuItem>
                                <MenuItem value={'Staff'}>Staff</MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                            </Controller>
                            <FormHelperText>
                                {errors?.membership?.message}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                {formSubmissionError ? (
                    <Alert style={{ marginTop: spacing(1) }} severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        Sorry, there was a problem submitting your details.
                        <br />
                        Please try entering your details again, or see the team
                        for assistance.
                    </Alert>
                ) : (
                    <></>
                )}
                <div style={{ position: 'relative' }}>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ margin: `${spacing(3, 0, 2)}` }}
                        disabled={loading}
                    >
                        Update
                    </Button>
                    {loading && (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: -12,
                                marginLeft: -12,
                            }}
                            size={24}
                        />
                    )}
                </div>
            </form>
        </MuiPickersUtilsProvider>
    );
};
