import { useMutation, gql, useQuery } from '@apollo/client';
import { getIdToken } from '../../utils/auth';

const UPDATE_USER_DETAILS = gql`
    mutation CreateOrUpdatePerson($person: PersonInputType!) {
        createOrUpdatePerson(person: $person) {
            id
        }
    }
`;
export const useUpdateUserDetailsMutation = () => {
    const token = getIdToken();
    const updateUser = useMutation(UPDATE_USER_DETAILS, {
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    return updateUser;
};

export const useErrorThrower = () => {
    const token = getIdToken();
    useQuery(
        gql`
            {
                throwError
            }
        `,
        {
            context: { headers: { Authorization: 'Bearer ' + token } },
        }
    );
};
