import { gql, useQuery } from '@apollo/client';
import {
    Container,
    useTheme,
    Box,
    Typography,
    Card,
    CardContent,
    LinearProgress,
} from '@material-ui/core';
import React from 'react';
import { useUser } from './useUser';
import { getIdToken } from '../../utils/auth';
import { RouteComponentProps } from '@reach/router';
import Layout from '../Layout';
import { Alert, AlertTitle } from '@material-ui/lab';
const COMPLETED_SURVEYS_FOR_USER_WITH_SITE = gql`
    query CompletedSurveysForUserWithSite($userId: String) {
        completed_surveys(
            where: { userId: { _eq: $userId } }
            order_by: { dateCompleted: desc }
        ) {
            surveyId
            dateCompleted
            survey {
                sfId
                site {
                    address
                    name
                }
            }
        }
    }
`;

const useCompletedSurveysForUserWithSite = () => {
    const { user } = useUser();
    const token = getIdToken();
    const completedSurveys = useQuery(COMPLETED_SURVEYS_FOR_USER_WITH_SITE, {
        variables: { userId: user?.id },
        context: { headers: { Authorization: 'Bearer ' + token } },
    });
    return completedSurveys;
};

export const CompletedSurveys = (props: RouteComponentProps) => {
    const { spacing } = useTheme();
    const { data, loading, error } = useCompletedSurveysForUserWithSite();
    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return (
            <Alert style={{ marginTop: spacing(1) }} severity='error'>
                <AlertTitle>Error</AlertTitle>
                There was an error loading your completed surveys.
            </Alert>
        );
    }
    return (
        <Layout title='Completed Surveys' heading='Completed Surveys'>
            <Container component='section' maxWidth='xl'>
                <Box color='text.secondary' m={spacing(3, 0)}>
                    <Typography variant='h4'>Your Completed Surveys</Typography>
                    {data.completed_surveys.map((cs) => (
                        <CompletedSurvey completedSurvey={cs} />
                    ))}
                    {data.completed_surveys.length === 0 && (
                        <Typography>
                            Your haven't completed any surveys
                        </Typography>
                    )}
                </Box>
            </Container>
        </Layout>
    );
};

export const CompletedSurvey = ({ completedSurvey }) => {
    const { spacing } = useTheme();

    return (
        <Card
            color='text.secondary'
            variant='outlined'
            style={{ margin: spacing(2) }}
        >
            <CardContent>
                <Typography variant='caption'>
                    {completedSurvey.survey.sfId}
                </Typography>
                <Typography style={{ margin: spacing(1, 0, 0) }} variant='h6'>
                    {completedSurvey.survey.site.name}
                </Typography>
                <Typography>{completedSurvey.survey.site.address}</Typography>
                <Typography style={{ margin: spacing(1, 0) }} variant='h6'>
                    Date Completed:{' '}
                    {new Date(
                        completedSurvey.dateCompleted
                    ).toLocaleDateString()}
                </Typography>
            </CardContent>
        </Card>
    );
};
